<template>
    <div class="modal-mask">
        <div class="modal-wrapper">
            <div class="modal-container"
                style="min-width: 45rem; width: 46rem;max-height: 46vh;min-height: 20vh;">
                <div class="modal-header">
                    <slot name="header">
                        <h3 v-if="(reminders?.completed == reminders?.total)" class="form-header" style="color: #00AD50;">{{ type }} {{ action === 'Destruct' ? 'Destroyed' : action }} successfully.
                        </h3>
                        <h3 v-if="((reminders?.completed !== reminders?.total) && (reminders?.completed > 0 || reminders?.partial > 0) && (reminders?.completed < reminders?.total || reminders?.partial < reminders?.total))" class="form-header" style="color: #ad7600;">{{ type }} {{ action }} {{reminders?.partial > 0 ? '' : 'partially'}} successful.
                        </h3>
                        <h3 v-if="reminders?.completed == 0 && reminders?.partial == 0 " class="form-header" style="color: #ad0e00;">{{ type }} {{ action }} unsuccessful.
                        </h3>
                        <button class="red-close-button" @click="closeModal()">
                            <i class="pi pi-times" style="padding: 0"></i>
                        </button>
                    </slot>
                </div>
                <div class="modal-body">
                    <p style="font-size: medium; text-align: start;">
                     <template v-if="!(reminders?.partial > 0 && reminders?.completed == 0)">
                       {{ `Successfully ${action === 'Destruct' ? 'Destroyed' : 'Signed off'} 
                           ${reminders?.completed} out of ${reminders?.total} ${type}.` }}
                       <br>
                     </template>
                     <template v-if="reminders?.partial > 0">
                       {{ `Successfully Partially Signed off ${reminders.partial} out of ${reminders?.total} ${type}.` }}
                       <br>
                     </template>
                     <template v-if="reminders.reminders?.length > 0 && action !== 'Destruct'">
                       Insufficient stock for the following items:
                     </template>
                   </p>
                    <div class="manage-table-width" v-if="reminders.reminders?.length > 0">
                        <table class="data-table" cellspacing="0">
                            <tr class="table-headers schedule-cells">
                                <th v-for="(item, index) in tableHead" :key="index">{{ item }}</th>
                            </tr>
                            <tbody class="table-data" v-for="(item, index) in reminders.reminders" :key="index">
                                <tr>
                                    <td v-for="(items, index) in tableCell" :key="index">{{ item[items] }}</td>
                                </tr>
                            </tbody>
                        </table>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
export default {
    props: ['reminders','type', 'action', 'tableHead', 'tableCell'],
    data() {
        return {}
    },
    methods: {
        closeModal() {
            this.$emit('close', true)
        }
    },
    mounted() {
        console.log("reminders>>", this.reminders)
    }
}
</script>


<style lang="scss" scoped>
@import "../../assets/css/main.scss";

.modal-body {
    padding: 0px 20px;
    padding-bottom: 20px;
}

.manage-table-width {
    margin: auto;
    display: flex;
    align-items: flex-start;
    justify-content: flex-start;
    max-height: calc(46vh - 140px);
    overflow-y: auto;
}
</style>